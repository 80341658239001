<template>
  <div>
    <b-row>
      <b-col
        sm="12"
      >
        <analytics-congratulation :data="user" />
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.created"
              statistic-title="Emission"
              icon="TrendingUpIcon"
              color="info"
            />
          </b-col>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.pending"
              statistic-title="Attente"
              icon="ActivityIcon"
              color="warning"
            />
          </b-col>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.waiting"
              statistic-title="Traitement"
              icon="ActivityIcon"
              color="warning"
            />
          </b-col>
          <b-col sm="6">
            <StatisticCardHorizontal
              :statistic="getCounts.failed"
              statistic-title="Echec"
              icon="FrownIcon"
              color="danger"
            />
          </b-col>
          <b-col sm="6">
            <StatisticCardHorizontal
              :statistic="getCounts.success"
              statistic-title="Validées"
              icon="TrendingDownIcon"
              color="primary"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="reviews.length > 0"
        sm="12"
        md="6"
        lg="6"
      >
        <b-card title="Avis et Note de nos utilisateurs">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in reviews"
              :key="index"
              icon="UserIcon"
              variant="primary"
            >
              <div class="d-flex justify-content-between flex-sm-row flex-row mb-sm-0 mb-1">
                <h6 class="text-small text-lowercase">
                  {{ item.name.split(" ")[0] }}
                </h6>
                <small class="timeline-item-time text-nowrap text-muted ml-1">
                  {{ item.created_at | moment("DD/MM/YY HH:mm") }}</small>
              </div>
              <span class="m-0 p-0">
                <b-form-rating
                  id="rating-readonly"
                  class="p-0"
                  no-border
                  :value="item.note"
                  readonly
                  inline
                  :variant="item.note >= 3 ? 'success' : 'danger'"
                />
              </span>
              <p class="text-small">
                {{ item.review }}
              </p>
            </app-timeline-item>
          </app-timeline>
          <b-pagination
            v-model="currentNotePage"
            :total-rows="totalNoteItems"
            :per-page="perPageNote"
            first-number
            last-number
            align="right"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="stats.length > 0"
        sm="12"
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Vos transactions
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Un apercu statistique de vos transactions.
          </b-card-sub-title>
          <vue-apex-charts
            type="donut"
            height="350"
            :options="userOptions"
            :series="userSeries"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
// import { kFormatter } from '@core/utils/filter'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// colors

const chartColors = {
  donut: {
    series1: '#ffe700',
    series2: '#b61d1d',
    series3: '#126e51',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
}
export default {
  components: {
    StatisticCardHorizontal,
    AnalyticsCongratulation,
    VueApexCharts,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
      supports: [],
      reviews: [],
      loaded: false,
      currentPage: 1,
      perPage: 5,
      currentNotePage: 1,
      perPageNote: 3,
      totalNoteItems: 0,
      pageOptions: [5, 10, 15, 20],
      totalItems: 0,
      stats: [],
      sysStats: [],
      user: {
        name: `${this.$store.getters.currentUser.name} ${this.$store.getters.currentUser.surname}`,
      },
    }
  },
  computed: {
    getCounts() {
      const data = {
        created: 0,
        pending: 0,
        success: 0,
        waiting: 0,
        failed: 0,
      }
      if (this.stats.length > 0) {
        this.stats.forEach(element => {
          if (element.state === 'CREATED') {
            data.created = Number(element.data)
          }
          if (element.state === 'PENDING') {
            data.pending = Number(element.data)
          }
          if (element.state === 'FAILED') {
            data.failed = Number(element.data)
          }
          if (element.state === 'SUCCESSFUL') {
            data.success = Number(element.data)
          }
          if (element.state === 'WAITING') {
            data.waiting = Number(element.data)
          }
        })
      }
      return data
    },
    userSeries() {
      return Object.values(this.getCounts)
    },
    userOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          chartColors.donut.series1,
          chartColors.donut.series5,
          chartColors.donut.series3,
          chartColors.donut.series4,
          chartColors.donut.series2,
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                },
              },
            },
          },
        },
        labels: ['INITIE', 'EN COURS', 'VALIDE', 'TRAITEMENT', 'ECHEC'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '12px',
                      },
                      value: {
                        fontSize: '12px',
                      },
                      total: {
                        fontSize: '14px',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.countExhange()
        this.getReviews()
      },
    },
    currentNotePage(val) {
      if (val) {
        this.getReviews()
      }
    },
    perPageNote(val) {
      if (val) {
        this.getReviews()
      }
    },
  },
  methods: {
    // kFormatter,
    async countExhange() {
      this.stats = await this.$http
        .get(`/stats-by-state-and-owner/${this.$store.getters.currentUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data)
        .catch(() => [])
    },
    cleanMessage(item) {
      return this.$sanitize(item)
    },
    async getReviews() {
      this.reviews = await this.$http
        .get(`/reviews/all-state/true/${this.perPageNote}?page=${this.currentNotePage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          this.totalNoteItems = res.data.meta.total
          return res.data.data
        })
        .catch(() => [])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
